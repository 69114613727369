<template>
  <div>
    <Toolbar class="p-mb-4">
      <template #left>
        <!-- <Button
          label="اضافة جديد"
          icon="pi pi-plus" v-tooltip="'اضافه جديد'"
          class="p-ml-2 p-button-success"
          v-if="$checkRoles('deliveryAdd')"
          @click="$router.push('delivery/add')"
        />
        <Button
          label="حذف"
          icon="pi pi-trash" v-tooltip="'حذف'"
          @click="deleteAll"
          v-if="$checkRoles('deliveryDelete')"
          :disabled="selectedItems.length == 0"
          class="p-button-danger"
        /> -->
      </template>

      <template #right>
        <!-- <Button icon="pi pi-cog" class="p-button-basic p-ml-2" /> -->
        <Button
          icon="pi pi-download"
          class="p-button-help"
          @click="exportCSV($event)"
          v-tooltip="'طباعه'"
        />
        <!-- <Button icon="pi pi-search" class="p-ml-2" />
        <Button icon="pi pi-times" class="p-button-danger" /> -->
      </template>
    </Toolbar>

    <DataTable
      ref="dt"
      :value="list"
      :paginator="true"
      class="p-datatable-customers table table-striped"
      :rows="10"
      dataKey="id"
      :rowHover="true"
      :selection.sync="selectedItems"
      :expandedRows.sync="expandedRows"
      :loading="loading"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      :rowsPerPageOptions="[10, 25, 50, 100,500,1000,10000]"
      currentPageReportTemplate="عرض {first} الى {last} من {totalRecords} مدخلات"
    >
      <template #header>
        <div class="table-header">
          قائمه ملاحظات تسليم العملاء
        </div>
      </template>
      <template #empty>
        لا يوجد بيانات
      </template>
      <template #loading>
        يتم تحميل البيانات. يرجي الانتظار...
      </template>
      <!-- <Column selectionMode="multiple" field="id" headerStyle="width: 3em" /> -->

      <!-- <Column field="id" header="رقم العقد" :sortable="true" /> -->
      <Column
        field="contractsId.clientsId.code"
        header="كود العميل"
        :sortable="true"
      />
      <Column
        field="contractsId.clientsId.name"
        header="اسم العميل"
        :sortable="true"
      />
      <Column
        field="contractsId.branchesId.name"
        header="اسم الفرع"
        :sortable="true"
      />
      <Column field="contractsId.date" header="تاريخ العقد" :sortable="true" />
      <Column field="contractsId.total" header="قيمه العقد" :sortable="true" />

      <Column
        field="id"
        header="تحويل الى اوامر التشغيل"
        v-if="$checkRoles('deliveryToInstalltion')"
        bodyStyle="text-align: center; overflow: visible"
      >
        <template #body="slotProps">
          <Button
           v-tooltip="'تحويل الى اوامر التشغيل'"

            type="button"
            :disabled="
              slotProps.data.contractsId.productsList.filter(
                (el) => el.taskBack == true,
              ).length == 0
            "
            @click="
              backDelivery(
                slotProps.data.id,
                slotProps.data.installationsId.id,
                slotProps.data.contractsId.productsList,
                slotProps.data.backDeliveryNote,
              )
            "
            icon="pi pi-question"
            class="p-button-warning p-ml-2  p-button-rounded"
          ></Button>
        </template>
      </Column>

      <Column
        field="id"
        header="ارجاع للتسليم"
        v-if="$checkRoles('deliveryToDelivery')"
        bodyStyle="text-align: center; overflow: visible"
      >
        <template #body="slotProps">
          <Button

           v-tooltip="'ارجاع للتسليم'"

            type="button"
            @click="
              toAddDelivery(slotProps.data.id, slotProps.data.contractsId.id)
            "
            icon="pi pi-reply"
            v-if="!slotProps.data.toDelivery"
            class="p-button-info p-ml-2  p-button-rounded"
          ></Button>
        </template>
      </Column>

      <Column :expander="true" headerStyle="width: 3rem" />

      <template #expansion="slotProps">
        <div class="orders-subtable">
          <DataTable
            class="p-datatable-customers table table-striped"
            :value="[slotProps.data]"
          >
            <template #header>
              قائمه الملاحظات
            </template>
            <template #empty>
              لا يوجد بيانات
            </template>

            <Column field="contractsId.note" header="ملاحظات العقد" />
            <Column field="note" header="ملاحظات التسليم" />
            <Column field="backDeliveryNote" header="ملاحظات السكيرتاريه" />
          </DataTable>
          <DataTable
            :value="slotProps.data.contractsId.productsList"
            class="p-datatable-customers table table-striped"
            :rows="10"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            :rowsPerPageOptions="[10, 25, 50, 100,500,1000,10000]"
            currentPageReportTemplate="عرض {first} الى {last} من {totalRecords} مدخلات"
          >
            <template #header>
              قائمه المنتجات والخدمات
            </template>
            <template #empty>
              لا يوجد بيانات
            </template>

            <Column field="productsId.name" header="المنتج" />
            <Column field="itemsId.name" header="الخدمه" />
            <Column field="usersId.name" header="الموظف المسئول" />

            <Column
              field="id"
              header="ارجاع الى التركيبات"
              bodyStyle="text-align: center; overflow: visible"
            >
              <template #body="slotProps2">
                <InputSwitch v-model="slotProps2.data.taskBack" />
              </template>
            </Column>
            <Column
              field="id"
              header="ارجاع لموظف اخر"
              bodyStyle="text-align: center; overflow: visible"
            >
              <template #body="slotProps2">
                <InputSwitch
                  :disabled="!slotProps2.data.taskBack"
                  v-model="slotProps2.data.taskBackUser"
                />
              </template>
            </Column>
          </DataTable>
        </div>
      </template>
    </DataTable>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [],
      selectedItems: [],
      displayBasic: false,
      expandedRows: [],
      loading: true,
      deliveryStepsList: [],
    };
  },
  methods: {
    backDelivery(id, installationsId, list, backDeliveryNote) {
      this.$http.delete(`delivery/${id}`).then(
        () => {
          this.$toast.add({
            severity: 'success',
            summary: 'تم بنجاح',
            detail: 'تم التحويل بنجاح',
            life: 3000,
          });

          this.getData();
          this.$http
            .put(`installations/${installationsId}`, {
              toDelivery: false,
              status: 'backDelivery',
              backDeliveryNote,
            })
            .then(() => {
              for (const it of list) {
                if (it.taskBack) {
                  let obj = {
                    status: null,
                    isReady: false,
                    distributionsDate: null,
                    revisionDate: null,
                    readyDate: null,
                  };
                  if (it.taskBackUser) {
                    obj.usersId = null;
                  }
                  this.$http.put(`productsList/` + it.id, obj).then(() => {});
                }
              }
              // this.$http
              //   .put(`productsList/byContractsId/${contractsId}`, {
              //     isReady: false,
              //     status: null,
              //   })
              //   .then(() => {});
            });
        },
        (err) => {
          this.$toast.add({
            severity: 'error',
            summary: 'هناك خطأ',
            detail: err.response.data.message,
            life: 3000,
          });
        },
      );
    },
    getData() {
      this.$http.get(`delivery/backDelivery`).then(
        (response) => {
          this.loading = false;
          this.list = response.data;
        },
        (err) => {
          this.loading = false;
          this.$toast.add({
            severity: 'error',
            summary: 'هناك خطأ',
            detail: err.response.data.message,
            life: 3000,
          });
        },
      );
    },

    toAddDelivery(id, contractsId) {
      this.$http
        .put(`delivery/${id}`, {
          backDelivery: false,
        })
        .then(
          () => {
            this.$toast.add({
              severity: 'success',
              summary: 'تم بنجاح',
              detail: 'تم التحويل بنجاح',
              life: 3000,
            });
            this.$http
              .put(`contracts/addInstalltions/${contractsId}`, {
                status: 'تحت التسليم',
              })
              .then(() => {
                this.getData();
              });
          },
          (err) => {
            this.$toast.add({
              severity: 'error',
              summary: 'هناك خطأ',
              detail: err.response.data.message,
              life: 3000,
            });
          },
        );
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
  },
  created() {
    if (!this.$checkRoles('deliveryNotesVeiw')) {
      this.$router.push('/admin/');
    }
    this.getData();
  },
};
</script>
